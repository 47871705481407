import React, { useState, useEffect} from 'react';
import './FullScreenVideoBackground.css'; // Make sure to create this CSS file


const FullScreenVideoBackground = () => {

const [backgrounds, setBackgrounds] = useState([]);

  useEffect(() => {
    const apiUrl = 'https://singletemp1.mrmaywho.com'
    // const apiUrl = process.env.REACT_APP_API_URL;

    fetch(`${apiUrl}/background/`)
      .then(response => response.json())
      .then(data => setBackgrounds(data))
      .catch(error => console.error('Error fetching background:', error));
  }, []);

  return (

    <div className="video-background-container">
    {backgrounds.map(background => (
      <video autoPlay muted loop playsInline className="video-background" key={background.id}>
        <source src={background.imageFile}  type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ))}
      <div className="overlay-text">
        <strong><h1 className="animate-text">Welcome to Our Site</h1></strong>
        <strong><p className="animate-text">Explore the experience</p></strong>
      </div>
    </div>
  );
};

export default FullScreenVideoBackground;
