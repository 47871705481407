// Navbar.js
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Navbar.css'; // Ensure this CSS file is created
import logoImage from '../App/head_logo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const animation = useSpring({
    to: { opacity: isOpen ? 1 : 0, height: isOpen ? 120 : 0 }, // Adjust height based on actual link size
    from: { opacity: 0, height: 0 },
  });

  return (
    <div className="navbar-container">
      <div className="logo-and-menu">
        <div className="logo">
          <img src={logoImage} alt="Logo" />
        </div>
        <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
          <div className={isOpen ? 'bar top cross' : 'bar top'}></div>
          <div className={isOpen ? 'bar middle' : 'bar middle show'}></div>
          <div className={isOpen ? 'bar bottom cross' : 'bar bottom'}></div>
        </div>
      </div>
      <animated.div className="navbar-links" style={animation}>
        <a href="#home">Home</a>
        <a href="#pricing">Products</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </animated.div>
      <div className="desktop-links">
        <a href="#home">Home</a>
        <a href="#pricing">Products</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </div>
    </div>
  );
};

export default Navbar;
