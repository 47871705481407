import React from 'react';import './App.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import PricingSection from '../PricingSection/PricingSection';
import ContactForm from '../ContactForm/ContactForm';
import About from '../About/About';
import FullScreenVideoBackground from '../FullScreenVideoBackground/FullScreenVideoBackground';


 

function App() {
  return (
    <div className="App">
      <div className="background-image"></div>
      <Navbar />
      <section className="home-section" id="home">
        <FullScreenVideoBackground />
      </section>
      <PricingSection />
      <section className="about-section" id="about">
        <About />
      </section>
      <section className="contact-section" id="contact">
        <ContactForm />
      </section>
      <Footer />
    </div>
  );
}

export default App;
