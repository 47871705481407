import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>Nam faucibus a quam eu varius. Mauris a ex a augue finibus rhoncus. Aliquam erat augue, iaculis ac lobortis id, cursus id mi. Donec tincidunt ut purus eget accumsan. Phasellus eu tortor condimentum, tempor libero sed, mattis leo. Curabitur sodales finibus venenatis. Integer hendrerit erat hendrerit lorem viverra tincidunt. Nulla quis dictum velit. Aliquam semper porta diam, et egestas erat auctor et. Duis sagittis volutpat nisi, faucibus aliquet nisl convallis vel. Duis varius imperdiet odio quis rutrum. Aliquam fringilla massa in lectus pulvinar luctus.</p>
     </div>
  );
};

export default About;
